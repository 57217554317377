export const productCategories = [
  { name: 'Todos', isActive: true },
  { name: 'Vinos', isActive: false },
  { name: 'Cervezas', isActive: false },
  { name: 'Refrescos', isActive: false },
  { name: 'Lacteos', isActive: false },
  { name: 'Aguas', isActive: false },
  { name: 'Licores', isActive: false },
  { name: 'Otros', isActive: false },
];

export const customerCategories = [
  { name: 'Todos', isActive: false },
  { name: 'Bar', isActive: false },
  { name: 'Restaurante', isActive: false },
];
